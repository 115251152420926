const ConfigAPI: any = {
  BaseUrlDev: "https://api.dev.solusinegeri.com/",
  BaseUrlProd: "https://api.solusinegeri.com/",
};
let baseUrl = "";
export const check = () => {
  if (process.env.NODE_ENV === "production") {
    baseUrl = ConfigAPI.BaseUrlProd;
  } else {
    baseUrl = ConfigAPI.BaseUrlDev;
  }
  return baseUrl;
};
export default ConfigAPI;
