import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useLocation } from "react-router";
import axios from "axios";
import { removeCompany, setCompany } from "../components/DataCompany";

const PrivacyPolicy = () => {
  const location: any = useLocation();
  const pathname = location?.pathname?.split("/");
  const initial = pathname[1].toUpperCase() || "";
  const [data, setData] = React.useState<any>(null);

  const getCompany = () => {
    if (initial !== "PRIVACY-POLICY")
      axios
        .get(`/user/info/company/get/${initial}`)
        .then((res) => {
          setCompany(res);
          setData(res.data.data);
        })
        .catch((err) => {
          setData(null);
          console.log(err);
        });
    else removeCompany();
  };

  React.useEffect(() => {
    if (initial) {
      getCompany();
    } else {
      removeCompany();
    }
  }, []);

  return (
    <section className="font-poppins">
      <Header newTab={true} company={data} />
      <div
        className={
          data
            ? "container relative md:top-[140px] top-[100px] mx-auto pb-24 px-5"
            : "container relative md:top-[130px] top-[90px] mx-auto pb-24 px-5"
        }
      >
        <div className="mb-4">
          <h1 className="md:text-4xl text-2xl font-semibold">
            Kebijakan Privasi Akun Pengguna
          </h1>
          <p className="text-base text-[#9A9999]">
            Kami berkomitmen untuk memberikan pengalaman bagi Anda sebaik
            mungkin dalam menikmati setiap layanan jasa keuangan yang kami
            berikan baik saat ini maupun di masa depan. Privasi dan keamanan
            Data pribadi Anda akan selalu menjadi hal yang sangat penting bagi
            kami. Oleh karena itu, kami menguraikan secara transparan bagaimana
            kami mengumpulkan, menyimpan, dan menggunakan Data Pribadi Anda
            sebagaimana tercantum di bawah ini.
          </p>
        </div>
        <div className="mb-4">
          <h1 className="md:text-2xl text-xl font-semibold">1. Umum</h1>
          <p className="text-base text-[#9A9999]">
            Kebijakan Privasi ini berlaku untuk layanan-layanan yang disediakan
            oleh PT. Fokus Teknologi Pembayaran dan menjelaskan bagaimana kami
            mengumpulkan, menggunakan, menyimpan, mentransfer, melindungi dan
            mengungkapkan “data pribadi” Pengguna, Sebagaimana dijelaskan secara
            lebih lengkap dalam Syarat dan Ketentuan. Dengan menyetujui
            Kebijakan Privasi ini, Pengguna menyatakan telah memahami dan
            menyetujui ketentuan dalam pemberitahuan ini berkaitan dengan
            Pengumpulan, penggunaan, penyimpanan, pemberian, dan penghapusan
            Data Pribadi Pengguna sebagaimana diatur dalam Kebijakan Privasi
            ini. Apabila Pengguna tidak memberikan Data Pribadi tersebut kepada
            kami, hal tersebut dapat menyebabkan kami tidak dapat menyediakan
            layanan-layanan kepada Pengguna atau mematuhi suatu peraturan
            perundang-undangan atau pedoman-pedoman yang dikeluarkan oleh
            instansi atau otoritas berwenang lainnya. Kata tersebut tidak
            bersifat membatasi.
          </p>
        </div>
        <div className="mb-4">
          <h1 className="md:text-2xl text-xl font-semibold">
            2. Pengumpulan Data Pribadi
          </h1>
          <p className="text-base text-[#9A9999]">
            Data Pribadi Pengguna dikumpulkan pada saat Pengguna mengakses
            Aplikasi atau melakukan transaksi menggunakan Aplikasi, di antaranya
            data yang berkaitan dengan:
          </p>
          <div className="pl-5">
            <div className="text-base text-[#9A9999] flex">
              <div className="min-w-[30px]">1.</div>
              <p>
                Informasi yang diperoleh (secara langsung atau tidak langsung)
                pada saat Pengguna mengakses Aplikasi, berkaitan dengan
                informasi tentang informasi perangkat.
              </p>
            </div>
            <div className="text-base text-[#9A9999] flex">
              <div className="min-w-[30px]">2.</div>
              <p>
                Informasi yang diperoleh (secara langsung atau tidak langsung)
                ketika Pengguna mendaftar ke akun untuk membuat Akun meliputi
                nama, tanggal lahir, alamat, nomor telepon, alamat surel
                Pengguna, dan kartu identitas.
              </p>
            </div>
            <div className="text-base text-[#9A9999] flex">
              <div className="min-w-[30px]">3.</div>
              <p>
                Informasi yang diperoleh (secara langsung atau tidak langsung)
                selama Pengguna menggunakan Aplikasi.
              </p>
            </div>
            <div className="text-base text-[#9A9999] flex">
              <div className="min-w-[30px]">4.</div>
              <p>
                Informasi yang diperoleh pada saat Pengguna melakukan pengkinian
                informasi atau pada saat lainnya sebagaimana dapat diminta oleh
                solusinegeri kepada Pengguna apabila dibutuhkan dari waktu ke
                waktu.
              </p>
            </div>
          </div>
        </div>
        <div className="mb-4">
          <h1 className="md:text-2xl text-xl font-semibold">
            3. Penggunaan Data Pribadi
          </h1>
          <p className="text-base text-[#9A9999]">
            Kami dapat mengolah, menganalisis, dan/atau menggunakan Data Pribadi
            yang kami peroleh tentang Pengguna untuk tujuan sebagai berikut
            maupun tujuan lain yang diizinkan oleh peraturan perundang-undangan
            yang berlaku, di antaranya:
          </p>
          <div className="pl-5">
            <div className="text-base text-[#9A9999] flex">
              <div className="min-w-[30px]">1.</div>
              <p>
                Memverifikasi kelayakan dan/atau data pribadi Pengguna untuk
                menggunakan fitur-fitur dan fungsi-fungsi dalam Aplikasi ataupun
                verifikasi apapun yang menurut pertimbangan Solusinegeri
                diperlukan sebelum memberikan layanan kepada Pengguna atau
                sebelum Solusinegeri memverifikasi pendaftaran Pengguna termasuk
                dan tidak terbatas pada proses Mengenal Pelanggan.
              </p>
            </div>
            <div className="text-base text-[#9A9999] flex">
              <div className="min-w-[30px]">2.</div>
              <p>
                Mengidentifikasi dan memproses pendaftaran Akun Pengguna, serta
                memelihara dan mengelola pendaftaran Pengguna.
              </p>
            </div>
            <div className="text-base text-[#9A9999] flex">
              <div className="min-w-[30px]">3.</div>
              <p>Memproses dan mengelola Saldo Pengguna.</p>
            </div>
            <div className="text-base text-[#9A9999] flex">
              <div className="min-w-[30px]">4.</div>
              <p>
                Menyediakan layanan bagi Pengguna dan layanan pelanggan yang
                terkait dengan penggunaan Akun Pengguna termasuk berkolaborasi
                untuk memfasilitasi pembayaran dan tagihan-tagihan Pengguna dan
                menjawab pertanyaan, masukan, keluhan, tuntutan atau masalah
                dari Pengguna.
              </p>
            </div>
            <div className="text-base text-[#9A9999] flex">
              <div className="min-w-[30px]">5.</div>
              <p>
                Menyediakan layanan bagi Pengguna yang berkaitan dengan
                penggunaan Aplikasi oleh Pengguna yaitu untuk mempermudah
                Pengguna.
              </p>
            </div>
            <div className="text-base text-[#9A9999] flex">
              <div className="min-w-[30px]">6.</div>
              <p>
                Mengelola risiko, melakukan pengecekan atas kelayakan kredit dan
                kemampuan membayar (solvensi), atau menilai, mendeteksi,
                menyelidiki, mencegah dan/atau memperbaiki penipuan atau
                kegiatan-kegiatan yang berpotensi melanggar atau melanggar
                peraturan perundang-undangan dan kepentingan, ataupun untuk
                melindungi integritas platform teknologi informasi kami.
              </p>
            </div>
            <div className="text-base text-[#9A9999] flex">
              <div className="min-w-[30px]">7.</div>
              <p>
                Memberitahu Pengguna mengenai segala pembaharuan pada Aplikasi
                atau perubahan pada layanan.
              </p>
            </div>
            <div className="text-base text-[#9A9999] flex">
              <div className="min-w-[30px]">8.</div>
              <p>
                Mengembangkan dan meningkatkan kualitas aplikasi untuk memenuhi
                kebutuhan dan preferensi pengguna terhadap fitur-fitur yang
                dimiliki atau akan dikembangkan kedepannya oleh Solusinegeri.
              </p>
            </div>
            <div className="text-base text-[#9A9999] flex">
              <div className="min-w-[30px]">9.</div>
              <p>
                Memberikan bantuan teknis atau dalam rangka menyelesaikan
                hambatan teknis dan masalah operasional yang terjadi pada
                aplikasi Solusinegeri.
              </p>
            </div>
            <div className="text-base text-[#9A9999] flex">
              <div className="min-w-[30px]">10.</div>
              <p>
                Kami juga dapat menggunakan Data Pribadi Pengguna dengan cara
                lain, yang akan kami sertakan dengan pemberitahuan khusus pada
                saat pengumpulan data tersebut untuk disetujui oleh Pengguna.
              </p>
            </div>
          </div>
        </div>
        <div className="mb-4">
          <h1 className="md:text-2xl text-xl font-semibold">
            4. Pengungkapan Data Pribadi
          </h1>
          <p className="text-base text-[#9A9999]">
            4.1 Data Pribadi Pengguna yang kami simpan akan dijaga
            kerahasiaannya, namun kami dapat memberikan Data Pribadi Pengguna
            kepada pihak-pihak lain untuk tujuan-tujuan sebagaimana diatur dalam
            ketentuan Bagian 3 di atas:
          </p>
          <div className="pl-5">
            <div className="text-base text-[#9A9999] flex">
              <div className="min-w-[30px]">1.</div>
              <p>
                Agen, kontraktor, dan penyedia layanan pihak ketiga yang bekerja
                sama dengan Solusinegeri dalam menyediakan layanan kami bagi
                pengguna termasuk untuk mencegah penipuan, pengumpulan tagihan,
                memasukkan data, manajemen basis data, promosi, pemasaran,
                riset, survei, layanan pelanggan, layanan teknologi,
                pemberitahuan produk dan layanan, serta layanan perpanjangan
                pembayaran.
              </p>
            </div>
            <div className="text-base text-[#9A9999] flex">
              <div className="min-w-[30px]">2.</div>
              <p>
                Merchant dan organisasi lain, seperti asosiasi kartu, jaringan
                pembayaran atau lembaga keuangan, kepada atau melalui siapa
                pembayaran-pembayaran tersebut dilakukan dengan menggunakan
                Solusinegeri.
              </p>
            </div>
            <div className="text-base text-[#9A9999] flex">
              <div className="min-w-[30px]">3.</div>
              <p>
                Lembaga keuangan pihak ketiga seperti bank dan payment provider
                yang bekerja sama dengan Solusinegeri dalam menunjang layanan
                aplikasi.
              </p>
            </div>
            <div className="text-base text-[#9A9999] flex">
              <div className="min-w-[30px]">4.</div>
              <p>Pihak ketiga penyedia jasa.</p>
            </div>
            <div className="text-base text-[#9A9999] flex">
              <div className="min-w-[30px]">5.</div>
              <p>
                Entitas-entitas yang terlibat dalam penggabungan,
                pengambilalihan, atau transaksi pembiayaan dengan kami.
              </p>
            </div>
            <p className="text-base text-[#9A9999] -ml-5">
              4.2 Solusinegeri juga dapat mengungkapkan dan membagikan Data
              Pribadi Pengguna dengan pihak-pihak yang disebutkan di atas untuk
              tujuan sebagai berikut ini:
            </p>
            <div className="text-base text-[#9A9999] flex">
              <div className="min-w-[30px]">6.</div>
              <p>
                Untuk meningkatkan kualitas layanan yang diberikan Solusinegeri
                kepada Pengguna dari waktu ke waktu.
              </p>
            </div>
            <div className="text-base text-[#9A9999] flex">
              <div className="min-w-[30px]">7.</div>
              <p>
                Jika diwajibkan atau diizinkan oleh peraturan perundang-undangan
                yang berlaku atau atas perintah dan/atau permintaan resmi dari
                otoritas, lembaga, institusi negara, dan/atau aparat penegak
                hukum.
              </p>
            </div>
            <div className="text-base text-[#9A9999] flex">
              <div className="min-w-[30px]">8.</div>
              <p>
                Untuk keperluan proses hukum dalam bentuk apa pun antara
                Pengguna dengan Solusinegeri.
              </p>
            </div>
            <div className="text-base text-[#9A9999] flex">
              <div className="min-w-[30px]">9.</div>
              <p>
                Untuk tujuan audit, baik rutin maupun insidentil, sebagaimana
                diperlukan dari waktu ke waktu;
              </p>
            </div>
            <div className="text-base text-[#9A9999] flex">
              <div className="min-w-[30px]">10.</div>
              <p>
                Untuk keperluan pemrosesan izin, pendaftaran, ataupun perekaman
                yang diperlukan untuk kegiatan usaha Solusinegeri;
              </p>
            </div>
            <div className="text-base text-[#9A9999] flex">
              <div className="min-w-[30px]">11.</div>
              <p>
                Untuk semua proses verifikasi yang diperlukan sebelum
                mendaftarkan Pengguna sebagai Pengguna, termasuk proses Mengenal
                Pelanggan.
              </p>
            </div>
            <p className="text-base text-[#9A9999] -ml-5">
              4.3 Solusinegeri tidak akan menjual atau menyewakan Data Pribadi
              Pengguna kepada pihak lain.
            </p>
            <p className="text-base text-[#9A9999] -ml-5">
              4.4 Selain sebagaimana diatur di atas, Solusinegeri dapat
              mengungkapkan dan membagikan Data Pribadi Pengguna untuk tujuan
              maupun kepada pihak lain, setelah memperoleh persetujuan Pengguna
              untuk melakukan pertukaran Data Pribadi tersebut.
            </p>
          </div>
        </div>
        <div className="mb-4">
          <h1 className="md:text-2xl text-xl font-semibold">
            5. Tindakan Keamanan dan Penyimpanan{" "}
          </h1>
          <p className="text-base text-[#9A9999]">
            Kami mengambil setiap langkah yang wajar dan semaksimal mungkin
            termasuk memberikan perlindungan teknis, administratif, dan fisik
            untuk membantu melindungi Data Pribadi Pengguna yang kami proses
            dari suatu risiko kehilangan, penyalahgunaan dan akses tanpa izin,
            pencurian, perubahan dan pembongkaran yang tidak diinginkan. Kami
            akan menyimpan dan memastikan penyedia layanan kami untuk menyimpan
            Data Pribadi Pengguna hanya selama dibutuhkan untuk tujuan-tujuan
            yang telah ditetapkan dalam pemberitahuan ini dan sesuai dengan
            persyaratan dalam peraturan perundang-undangan yang berlaku. Data
            Pribadi Pengguna hanya akan disimpan selama diperlukan untuk
            memenuhi tujuan pengumpulannya atau selama penyimpanan tersebut
            diwajibkan atau diizinkan oleh peraturan perundang-undangan yang
            berlaku. Bagi Pengguna yang terdaftar pada Solusinegeri maka
            informasi pendaftaran dan informasi Akun Pengguna (jika ada) dapat
            dilihat dan diubah dalam Aplikasi Solusinegeri melalui Akun
            Pengguna, yang dilindungi oleh kata sandi, kode PIN, atau metode
            keamanan lainnya. Kami mengingatkan kembali kepada Pengguna untuk
            tidak mengungkapkan kata sandi atau kode PIN kepada pihak mana pun.
            Karyawan, petugas, agen, dan perwakilan kami tidak mengetahui dan
            tidak akan pernah meminta kata sandi pengguna dalam komunikasi
            melalui telepon atau media komunikasi lain yang tidak diminta atau
            melalui media email yang tidak diminta. Jika Pengguna menggunakan
            perangkat yang dapat digunakan oleh orang lain (perangkat publik),
            maka Pengguna harus memilih untuk tidak menyimpan informasi log-in
            Pengguna (misalnya: ID pengguna dan kata sandi) pada perangkat
            tersebut. Anda bertanggung jawab untuk menjaga kerahasiaan detail
            akun Anda, termasuk kata sandi, kode PIN, dan One Time Password
            (OTP) dari siapa pun dan bertanggung jawab atas keamanan perangkat
            yang Anda gunakan.
          </p>
        </div>
        <div className="mb-4">
          <h1 className="md:text-2xl text-xl font-semibold">
            6. Layanan Pihak Ketiga dan Situs Web
          </h1>
          <p className="text-base text-[#9A9999]">
            Aplikasi Solusinegeri menyediakan tautan ke situs web dan layanan
            lain (termasuk aplikasi yang dioperasikan oleh pihak ketiga) untuk
            kemudahan dan informasi pengguna. Layanan dan situs web ini dapat
            beroperasi secara independen dari kami dan mungkin memiliki
            pemberitahuan atau kebijakan privasinya sendiri, kami sarankan
            pengguna untuk membaca terlebih dahulu sebelum menggunakan layanan
            mereka atau melakukan aktivitas apa pun di situs web tersebut.
            Menyediakan tautan ke situs web dan layanan lain sifatnya hanya
            merupakan informasi saja. Kami tidak memberikan dukungan atau
            jaminan apa pun terhadap konten dan informasi yang dimuat dalam
            publikasi tersebut. Terhadap situs web yang dikunjungi Pengguna yang
            tidak dimiliki atau dikendalikan oleh kami, kami tidak bertanggung
            jawab atas konten, praktik privasi, dan kualitas layanan tersebut.
          </p>
        </div>
        <div className="mb-4">
          <h1 className="md:text-2xl text-xl font-semibold">
            7. Perubahan atas Kebijakan Privasi ini
          </h1>
          <p className="text-base text-[#9A9999]">
            Solusinegeri memegang hak eksklusif untuk mengubah, menambah,
            dan/atau meninjau pemberitahuan dan informasi terkait Kebijakan
            Privasi ini dari waktu ke waktu, di mana Pengguna akan diminta untuk
            memberikan persetujuan terhadap Kebijakan Privasi yang telah diubah,
            ditambah, dan/atau ditinjau. Apabila Pengguna tidak memberikan
            persetujuan terhadap Kebijakan Privasi yang telah diubah, ditambah,
            dan/atau direvisi tersebut, maka Solusinegeri memiliki kewenangan
            penuh untuk menghentikan penyediaan layanan-layanan dalam Aplikasi
            Solusinegeri kepada Pengguna. Anda setuju bahwa Anda bertanggung
            jawab untuk meninjau Kebijakan Privasi ini secara berkala untuk
            mendapatkan informasi terbaru mengenai pemrosesan data dan praktik
            perlindungan data.
          </p>
        </div>
        <div className="mb-4">
          <h1 className="md:text-2xl text-xl font-semibold">
            8. Ketentuan Lain
          </h1>
          <div className="pl-5">
            <div className="text-base text-[#9A9999] flex">
              <div className="min-w-[30px]">1.</div>
              <p>
                Peraturan Data Pribadi ini tunduk, diatur, dan dilaksanakan
                sesuai dengan hukum Republik Indonesia.
              </p>
            </div>
            <div className="text-base text-[#9A9999] flex">
              <div className="min-w-[30px]">2.</div>
              <p>
                Kami akan menerapkan sistem keamanan informasi yang kami anggap
                memadai dan sesuai dengan standar industri atau peraturan
                perundang-undangan. Namun, transmisi informasi melalui saluran
                komunikasi tidak sepenuhnya aman dan bebas dari celah. Dengan
                demikian, setiap transmisi informasi oleh Pengguna kepada kami
                memiliki risiko keamanan, di mana risiko tersebut ditanggung
                oleh Pengguna. Kami tidak menjamin keamanan basis data kami dan
                kami juga tidak menjamin bahwa data pengguna sepenuhnya tidak
                akan tertahan/terganggu saat dikirimkan kepada kami.
              </p>
            </div>
          </div>
        </div>
        <div className="mb-4">
          <h1 className="md:text-2xl text-xl font-semibold">9. Pernyataan</h1>
          <p className="text-base text-[#9A9999]">
            Dengan menggunakan Aplikasi Solusinegeri, Pengguna mengakui bahwa
            Pengguna telah membaca dan memahami Peraturan Data Pribadi ini dan
            menyetujui Pengumpulan, penggunaan, pengolahan, penyimpanan,
            pertukaran, dan penghapusan Data Pribadi sebagaimana diatur dalam
            Kebijakan Privasi ini.
          </p>
        </div>
        <div className="mb-4">
          <h1 className="md:text-2xl text-xl font-semibold">
            10. Menjaga Keamanan Data Anda
          </h1>
          <p className="text-base text-[#9A9999]">
            Solusinegeri berkomitmen untuk melindungi Data Pribadi Anda sebagai
            Pengguna kami dimana kami berusaha mengimplementasikan
            langkah-langkah teknis sebaik mungkin, sesuai dengan standar
            industri atau peraturan perundang-undangan, untuk melindungi
            keamanan Data Pribadi Anda. Namun, sebagai Pengguna, Anda harus
            memahami bahwa tidak ada sistem yang sepenuhnya aman. Oleh karena
            itu, sebagai Pengguna, Anda wajib menerapkan langkah-langkah untuk
            melindungi Akun Pengguna dengan menjaga penguasaan Nomor Ponsel dan
            Perangkat yang digunakan untuk mengakses Aplikasi, serta menggunakan
            serta meretas kata sandi dan/atau kode PIN yang kuat dan unik untuk
            mencegah akses. yang tidak diinginkan.
          </p>
        </div>
        <div className="mb-4">
          <h1 className="md:text-2xl text-xl font-semibold">
            11. Penyimpanan, Akses, Koreksi, Pembaharuan, dan Penghapusan Data
            Anda
          </h1>
          <p className="text-base text-[#9A9999]">
            11.1 Solusinegeri menyimpan Data Pribadi Anda sebagai Pengguna
            selama diperlukan untuk menyediakan layanan dan untuk tujuan bisnis
            yang sah dan penting, meliputi:
          </p>
          <div className="pl-5">
            <div className="text-base text-[#9A9999] flex">
              <div className="min-w-[30px]">1.</div>
              <p>
                Dalam rangka mempertahankan kinerja dan kualitas layanan pada
                fitur-fitur Solusinegeri;
              </p>
            </div>
            <div className="text-base text-[#9A9999] flex">
              <div className="min-w-[30px]">2.</div>
              <p>
                Dalam rangka menyelesaikan masalah yang belum terselesaikan
                terkait akun Anda, seperti chargeback , refund , ataupun
                pertanyaan lainnya, dimana kami akan menyimpan Data Pribadi Anda
                sampai masalah tersebut terselesaikan;
              </p>
            </div>
            <div className="text-base text-[#9A9999] flex">
              <div className="min-w-[30px]">3.</div>
              <p>
                Dalam rangka memenuhi kewajiban hukum, pajak, audit, dan
                akuntansi kami, kami akan menyimpan Data Pribadi yang diperlukan
                selama periode yang ditetapkan berdasarkan hukum yang berlaku;
                dan
              </p>
            </div>
            <div className="text-base text-[#9A9999] flex">
              <div className="min-w-[30px]">4.</div>
              <p>
                Atau untuk tujuan lain seperti yang telah disebutkan diatas
                selama diperlukan untuk kepentingan bisnis kami, seperti untuk
                mencegah penipuan atau menjaga keamanan anda sebagai Pengguna.
              </p>
            </div>
          </div>
          <p className="text-base text-[#9A9999]">
            11.2 Anda memiliki hak untuk meminta akses terhadap data pribadi
            historis yang pernah diserahkan kepada kami. Dalam hal demikian,
            kami akan menyediakan informasi tersebut dengan format yang kami
            tentukan, dengan memperhatikan kompatibilitas dan praktik yang
            wajar. Anda dapat menyampaikan permintaan akses tersebut melalui
            Pusat Bantuan yang tersedia di aplikasi, atau informasi kontak yang
            disediakan.
          </p>
          <p className="text-base text-[#9A9999]">
            11.3 Anda memiliki hak untuk melakukan koreksi atau memperbarui Data
            Pribadi yang pernah diserahkan kepada kami. Anda dapat melakukan
            koreksi atau pembaharuan tersebut melalui Aplikasi atau melalui
            Pusat Bantuan yang tercantum di aplikasi atau informasi kontak yang
            disediakan.
          </p>
          <p className="text-base text-[#9A9999]">
            11.4 Anda memiliki hak untuk meminta penghapusan terhadap Data
            Pribadi yang pernah diserahkan kepada kami yang sudah tidak relevan,
            sesuai dengan ketentuan peraturan perundang-undangan. Anda dapat
            menyampaikan permintaan tersebut melalui Pusat Bantuan yang
            tercantum di aplikasi atau informasi kontak yang disediakan.
          </p>
        </div>
        <div className="mb-4">
          <h1 className="md:text-2xl text-xl font-semibold">
            12. Informasi lebih lanjut
          </h1>
          <p className="text-base text-[#9A9999]">
            Apabila Pengguna hendak mengajukan pertanyaan atau menyampaikan
            keluhan, silakan menghubungi Layanan Pelanggan (Customer Service)
            dibawah ini:
          </p>
          <p className="text-base text-[#9A9999]">
            PT. Fokus Teknologi Pembayaran (Solusinegeri) <br /> Patra Jasa
            Office - Tower Lt. 17 Room 1704, Jalan Jendral Gatot Subroto Kavling
            32-24, RT 001 RW 003, Kelurahan Kuningan Timur, Kecamatan Setiabudi,
            Provinsi DKI Jakarta, Jakarta Selatan <br />
            Jam Kerja: Senin – Minggu, pukul 08.00 – 17.00 WIB <br />
            Telepon: +62 895 26643223 <br /> Email: cs.closepay@gmail.com
          </p>
        </div>
        <div className="mb-16">
          <p className="text-base text-[#9A9999]">
            Dengan menyetujui Kebijakan Privasi ini, Anda mengakui bahwa Anda
            telah membaca dan memahami Kebijakan Privasi ini dan menyetujui
            semua ketentuan yang tercantum di dalamnya. Anda juga telah
            memberikan persetujuan kepada Solusinegeri untuk mengumpulkan,
            menggunakan, membagikan, mengungkapkan, menyimpan, mentransfer, atau
            mengolah Data Pribadi Anda sesuai dengan Kebijakan Privasi ini.
          </p>
        </div>
      </div>
      <Footer newTab={true} company={data} />
    </section>
  );
};

export default PrivacyPolicy;
