const data: any = localStorage.getItem("company");
export const company = JSON.parse(data);

export const removeCompany = () => {
  return localStorage.removeItem("company");
};

export const setCompany = (res: any) => {
  return localStorage.setItem("company", JSON.stringify(res.data.data));
};
