import Home from "./pages/Home";
import TermCondition from "./pages/TermCondition";
import PrivacyPolicy from "./pages/PrivacyPolicy";

const Routes: any = [
  {
    path: "",
    component: Home,
  },
  {
    path: ":cid",
    component: Home,
  },
  {
    path: ":cid/term-condition",
    component: TermCondition,
  },
  {
    path: "term-condition",
    component: TermCondition,
  },
  {
    path: ":cid/privacy-policy",
    component: PrivacyPolicy,
  },
  {
    path: "privacy-policy",
    component: PrivacyPolicy,
  },
];

export default Routes;
