import axios from "axios";
import ConfigAPI from "./ConfigApi";
import { toast } from "react-toastify";

export default function setupAxios() {
  axios.defaults.baseURL = ConfigAPI.BaseUrlProd;
  axios.defaults.timeout = 30000;

  axios.interceptors.request.use(
    (config: any) => {
      const sessionId = localStorage.getItem("sessionId");

      if (sessionId && !config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${sessionId}`;
      }

      return config;
    },
    (err) => {
      Promise.reject(err);
    }
  );
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      console.log({ error });
      const { status } = error.response ?? {};
      const errorMessage =
        error.response?.data?.detail?.message ?? error.message;
      if (status === 401) {
        localStorage.removeItem("sessionId");
      } else {
        toast.error(errorMessage);
      }
      throw error;
    }
  );
}
