import React from "react";
import { Routes, Route } from "react-router-dom";
import Routing from "./Routes";
import Logo from "./assets/images/logo_.png";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { company } from "./components/DataCompany";

function App() {
  function getFaviconEl() {
    return document.getElementById("favicon");
  }

  React.useEffect(() => {
    const favicon: any | null = getFaviconEl();
    favicon.href = Logo;
    document.title = "Solusi Negeri";
  }, []);

  return (
    <div>
      <ToastContainer />
      <Routes>
        {Routing.map((prop: any, index: any) => {
          return (
            <Route
              key={"menu-" + index}
              path={prop.path}
              element={<prop.component />}
            />
          );
        })}
      </Routes>
    </div>
  );
}

export default App;
