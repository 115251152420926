import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useLocation } from "react-router";
import axios from "axios";
import { removeCompany, setCompany } from "../components/DataCompany";

const TermCondition = () => {
  const location: any = useLocation();
  const pathname = location?.pathname?.split("/");
  const initial = pathname[1].toUpperCase() || "";
  const [data, setData] = React.useState<any>(null);

  const getCompany = () => {
    if (initial !== "TERM-CONDITION")
      axios
        .get(`/user/info/company/get/${initial}`)
        .then((res) => {
          setCompany(res);
          setData(res.data.data);
        })
        .catch((err) => {
          setData(null);
          console.log(err);
        });
    else removeCompany();
  };

  React.useEffect(() => {
    if (initial) {
      getCompany();
    } else {
      removeCompany();
    }
  }, []);

  return (
    <section className="font-poppins">
      <Header newTab={true} company={data} />
      <div
        className={
          data
            ? "container relative md:top-[140px] top-[100px] mx-auto pb-24 px-5"
            : "container relative md:top-[130px] top-[90px] mx-auto pb-24 px-5"
        }
      >
        <div className="mb-4">
          <h1 className="md:text-4xl text-2xl font-semibold">
            Term and Condition
          </h1>
          <p className="text-base text-[#9A9999]">
            Welcome to the terms and conditions on solusinegeri.com. The terms &
            conditions set out below govern the use of services offered by PT.
            Fokus Teknologi Pembayaran regarding the use of the solusinegeri
            site and the application. Users are advised to read carefully
            because it can impact the rights and obligations of Users under the
            law. By registering and/or using solusinegeri.com site, users are
            deemed to have read, understand, acknowledge and agree to all
            contents in the Terms & Conditions. These terms & conditions are a
            form of agreement set forth in a legal agreement between the Users
            and PT. Fokus Teknologi Pembayaran. By using solusinegeri.com, the
            User automatically agrees to the terms and conditions and/or changes
            to the applicable
          </p>
        </div>
        <div className="mb-4">
          <h1 className="md:text-4xl text-2xl font-semibold">Account</h1>
          <ul className="list-disc text-base text-[#9A9999] px-5">
            <li>
              Account or user is a party (registered / unregistered) who
              accesses the solusinegeri application or website, the party is
              obliged to comply with the rules for using solusinegeri.com.
            </li>
            <li>
              solusinegeri without prior notification to the User, has the
              authority to take necessary actions for any suspected violation or
              violation of the Terms & conditions and / or applicable law.
            </li>
            <li>
              Users are prohibited from manipulating solusinegeri.com software
              or data.
            </li>
          </ul>
        </div>
        <div className="mb-4">
          <h1 className="md:text-4xl text-2xl font-semibold">
            Sales Transaction
          </h1>
          <ul className="list-disc text-base text-[#9A9999] px-5">
            <li>
              Sellers are required to follow the procedures in the
              solusinegeri.com transaction flow and must follow the goods or
              services terms and conditions on solusinegeri.com. Sellers are not
              allowed to sell goods or services prohibited by solusinegeri.com
              in accordance with the Types of Goods and Services Glossary.
            </li>
            <li>
              The seller understands and agrees that all taxes related to the
              transaction will be reported and handled by each party in
              accordance to the applicable tax provisions in the laws and
              regulations in your country.
            </li>
            <li>
              The seller fully understands and agrees that the invoice issued is
              in the name or the email of the seller.
            </li>
            <li>
              The seller agrees to use solusinegeri.com as a third party as a
              joint account to maintain the security of transactions with the
              buyer.
            </li>
            <li>
              The seller agrees to the decision made by solusinegeri.com in the
              event of a problem between the seller and the buyer in accordance
              with the Problem Decision Glossary.
            </li>
          </ul>
        </div>
        <div className="mb-4">
          <h1 className="md:text-4xl text-2xl font-semibold">Purchase</h1>
          <ul className="list-disc text-base text-[#9A9999] px-5">
            <li>
              Buyers are not allowed to transact goods or services prohibited by
              solusinegeri.com in accordance with the Types of Goods and
              Services Glossary.
            </li>
            <li>
              The buyer fully understands and agrees that the invoice issued is
              in the name or the email of the buyer.
            </li>
            <li>
              The buyer agrees to use solusinegeri.com as a third party as a
              joint account to maintain the security of transactions with the
              seller.
            </li>
            <li>
              The buyer agrees to the decision made by solusinegeri.com in the
              event of a problem between the seller and the buyer in accordance
              with the Problem Decision Glossary.
            </li>
          </ul>
        </div>
        <div className="mb-4">
          <h1 className="md:text-4xl text-2xl font-semibold">
            Transactions Payment
          </h1>
          <ul className="list-disc text-base text-[#9A9999] px-5">
            <li>
              Transactions payment use only the existing payments methods on the
              solusinegeri.com system.
            </li>
            <li>
              In the event of payment outside solusinegeri.com system,
              users/accounts shall report the payments on solusinegeri customer
              support to make payment approvement / or proof of payment.
            </li>
            <li>
              Payment of transactions by users will be charged for system
              maintenance purposes and service improvements in transactions in
              accordance with solusinegeri.com internal provisions.
            </li>
          </ul>
        </div>
        <div className="mb-4">
          <h1 className="md:text-4xl text-2xl font-semibold">Income Balance</h1>
          <ul className="list-disc text-base text-[#9A9999] px-5">
            <li>
              The income balance is obtained from the results of product or
              service transactions.
            </li>
            <li>
              Income balance cannot be used as a payment method for purchase
              transactions.
            </li>
            <li>
              The income balance can only make withdrawals to the bank account
              registered in the user's account.
            </li>
          </ul>
        </div>
        <div className="mb-4">
          <h1 className="md:text-4xl text-2xl font-semibold">Withdrawal</h1>
          <ul className="list-disc text-base text-[#9A9999] px-5">
            <li>
              Withdrawals from the same banks will be processed within 1x24
              hours of working days, while interbank funds withdrawals will be
              processed within 2x24 hours of working days.
            </li>
            <li>
              For withdrawal of funds to an account number outside the BCA and
              BNI bank, if there are additional fees charged, it will be borne
              by the User.
            </li>
            <li>
              In the event that an alleged violation of the solusinegeri.com
              Terms and Conditions is found, solusinegeri has the right to
              unilaterally cancel the withdrawal request from the user.
            </li>
          </ul>
        </div>
        <div className="mb-4">
          <h1 className="md:text-4xl text-2xl font-semibold">
            Types of Goods and Services
          </h1>
          <ul className="list-disc text-base text-[#9A9999] px-5">
            <li>
              The following are the types of goods and services that are
              prohibited or limited by solusinegeri.com:.
            </li>
            <li>
              All types of drugs or illegal substances that are prohibited by
              applicable law.
            </li>
            <li>
              Goods or services that endanger the safety of living things.
            </li>
            <li>
              Other items that violate the applicable law in your country.
            </li>
          </ul>
        </div>
        <div className="mb-4">
          <h1 className="md:text-4xl text-2xl font-semibold">
            solusinegeri Resolution
          </h1>
          <ul className="list-disc text-base text-[#9A9999] px-5">
            <li>
              solusinegeri Resolution is a feature provided by solusinegeri.com
              to facilitate the resolution of transaction problems between
              buyers and sellers.
            </li>
            <li>
              In the use of this feature, buyers and sellers are expected to
              provide proof of sale and purchase transactions in the form of
              photos of goods, purchase notes, shipping receipt slips and other
              supporting evidence that can be the basis for justifying any
              arguments issued by each party to solusinegeri.com customer
              support.
            </li>
            <li>
              Resolution of problems through solusinegeri Resolution can be in
              the form of solutions generated based on mutual agreement between
              buyers and sellers.
            </li>
            <li>
              Buyers or sellers are expected to participate in solusinegeri
              resolutions.
            </li>
            <li>
              If one of the parties does not provide an answer, then within 2 x
              24 hours from the time the problem was made, the problem will
              automatically be resolved based on the solution proposed by the
              buyer or seller.
            </li>
            <li>
              solusinegeri Resolution does not take sides with one party,
              solusinegeri.com will behave as fairly as possible to the parties
              involved in the problem.
            </li>
          </ul>
        </div>
        <div className="mb-4">
          <h1 className="md:text-4xl text-2xl font-semibold">Renewal</h1>
          <ul className="list-disc text-base text-[#9A9999] px-5">
            <li>
              Terms & conditions may be changed and / or updated from time to
              time without prior notice. solusinegeri.com users are advised to
              read the solusinegeri.com Terms and Conditions more often if there
              are changes in the solusinegeri web or application.
            </li>
          </ul>
        </div>
      </div>
      <Footer newTab={true} company={data} />
    </section>
  );
};

export default TermCondition;
