/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC } from "react";
import logoSolusiNegeri from "../assets/images/solusi_negeri_logo_black.png";
import burgerButton from "../assets/images/burger_button.svg";
import { Link } from "react-scroll";
import { Link as RouterLink, useLocation } from "react-router-dom";

const links = [
  {
    name: "Tentang Kami",
    url: "about",
  },
  {
    name: "Portofolio",
    url: "portofolio",
  },
  {
    name: "Fitur",
    url: "features",
  },
  {
    name: "Keuntungan",
    url: "benefit",
  },
];

interface HeaderProps {
  newTab?: boolean;
  company?: any;
}

const Header: FC<HeaderProps> = (props): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const [newTab, setNewTab] = React.useState(false);
  const [linkUrl, setLinkUrl] = React.useState("");

  React.useEffect(() => {
    if (props.newTab !== undefined) setNewTab(props.newTab);
    if (window.location.origin === "https://solusinegeri.com") {
      setLinkUrl("https://solusinegeri.com");
    } else {
      setLinkUrl("https://dev.solusinegeri.com");
    }
  }, []);

  return (
    <header className="fixed z-[60] bg-white w-full shadow-3xl">
      {newTab ? (
        <nav className="container flex justify-between items-center mx-auto lg:py-5 py-1 lg:px-[0px] px-6">
          <RouterLink
            to={
              props.company ? linkUrl + `/${props.company?.initial}` : linkUrl
            }
          >
            <img
              src={
                props.company ? props.company?.companyLogo : logoSolusiNegeri
              }
              alt=""
              className={props.company ? "h-7 sm:h-10 md:h-10" : "h-16"}
            />
            {props.company && (
              <p className="ml-2 md:text-lg lg:text-lg sm:text-sm text-sm mt-2">
                {props.company?.name}
              </p>
            )}
          </RouterLink>
          {!open && (
            <img
              src={burgerButton}
              className="lg:hidden block right-5 cursor-pointer z-20 top-6"
              onClick={() => setOpen(!open)}
              alt=""
            />
          )}
          <div
            className={`bg-white flex lg:flex-row flex-col lg:justify-between justify-start lg:pl-[0px] lg:pr-[0px] pr-28 lg:static fixed duration-500 ease-linear top-0 lg:w-2/3 lg:h-auto h-screen z-50 pl-5 lg:pt-[0px] pt-5 ${
              !open ? "right-[-100%] " : "right-0"
            }`}
          >
            <img
              src={burgerButton}
              className="lg:hidden block right-5 cursor-pointer z-20 top-6 mb-6 w-6"
              onClick={() => setOpen(!open)}
              alt=""
            />
            <div className="flex lg:flex-row flex-col lg:items-center items-start lg:gap-[30px] md:gap-[20px] gap-3 custom-navbar">
              {links.map((item, index) => (
                <RouterLink
                  to={linkUrl}
                  state={item.url}
                  key={index}
                  className="hover:text-primary text-[#C4C4C4] hover:green-border cursor-pointer font-medium lg:text-base text-sm hover:green-border"
                >
                  {item.name}
                </RouterLink>
              ))}
            </div>
            <div className="flex lg:flex-row flex-col lg:items-center items-start gap-3 lg:mt-[0px] mt-3">
              <RouterLink
                to={`${
                  window.location.origin === "https://solusinegeri.com"
                    ? "https://app.solusinegeri.com"
                    : "https://app.dev.solusinegeri.com"
                }`}
                target="_blank"
                className="text-[#C4C4C4] hover:text-primary font-medium lg:text-base text-sm no-underline"
              >
                Login
              </RouterLink>
              <button
                type="button"
                onClick={() => window.open("https://wa.me/+6289526643223")}
                className="bg-primary text-white font-medium lg:text-base text-sm rounded-[10px] py-[10px] lg:px-[30px] px-[20px] w-full"
              >
                Hubungi Kami
              </button>
            </div>
          </div>
        </nav>
      ) : (
        <nav className="container flex justify-between items-center mx-auto lg:py-5 py-1 lg:px-[0px] px-6">
          <RouterLink
            to={
              props.company ? linkUrl + `/${props.company?.initial}` : linkUrl
            }
          >
            <img
              src={
                props.company ? props.company?.companyLogo : logoSolusiNegeri
              }
              alt=""
              className={props.company ? "h-11" : "h-16"}
            />
            {props.company && (
              <p className="ml-2 md:text-lg lg:text-lg sm:text-sm text-sm mt-2">
                {props.company?.name}
              </p>
            )}
          </RouterLink>
          {!open && (
            <img
              src={burgerButton}
              className="lg:hidden block right-5 cursor-pointer z-20 top-6"
              onClick={() => setOpen(!open)}
              alt=""
            />
          )}
          <div
            className={`bg-white flex lg:flex-row flex-col lg:justify-between justify-start lg:pl-[0px] lg:pr-[0px] pr-28 lg:static fixed duration-500 ease-linear top-0 lg:w-2/3 lg:h-auto h-screen z-50 pl-5 lg:pt-[0px] pt-5 ${
              !open ? "right-[-100%] " : "right-0"
            }`}
          >
            <img
              src={burgerButton}
              className="lg:hidden block right-5 cursor-pointer z-20 top-6 mb-6 w-6"
              onClick={() => setOpen(!open)}
              alt=""
            />
            <div className="flex lg:flex-row flex-col lg:items-center items-start lg:gap-[30px] md:gap-[20px] gap-3 custom-navbar">
              {links.map((item, index) => (
                <Link
                  to={item.url}
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={500}
                  key={index}
                  className="hover:text-primary text-[#C4C4C4] hover:green-border cursor-pointer font-medium lg:text-base text-sm hover:green-border"
                  onSetInactive={() => setOpen(false)}
                  onSetActive={() => setOpen(false)}
                >
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="flex lg:flex-row flex-col lg:items-center items-start gap-3 lg:mt-[0px] mt-3">
              <RouterLink
                to={`${
                  window.location.origin === "https://solusinegeri.com"
                    ? "https://app.solusinegeri.com"
                    : "https://app.dev.solusinegeri.com"
                }`}
                target="_blank"
                className="text-[#C4C4C4] hover:text-primary font-medium lg:text-base text-sm no-underline"
              >
                Login
              </RouterLink>
              <button
                type="button"
                onClick={() => window.open("https://wa.me/+6289526643223")}
                className="bg-primary text-white font-medium lg:text-base text-sm rounded-[10px] py-[10px] lg:px-[30px] px-[20px] w-full"
              >
                Hubungi Kami
              </button>
            </div>
          </div>
        </nav>
      )}
    </header>
  );
};

export default Header;
