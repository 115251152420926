/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC } from "react";
import { Link } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import backgroundBlack from "../assets/images/bg-black2.png";
import logoSolusiNegeri2 from "../assets/images/solusi_negeri_logo.png";

const navbarLinks = [
  {
    name: "Tentang Kami",
    url: "about",
  },
  {
    name: "Portofolio",
    url: "portofolio",
  },
  {
    name: "Fitur",
    url: "features",
  },
  {
    name: "Keuntungan",
    url: "benefit",
  },
];

const footerLinks = [
  {
    name: "Term Of Us",
    url: "term-condition",
  },
  // {
  //   name: "Cookies Use",
  //   url: "cookies"
  // },
  {
    name: "Contact",
    url: "contact",
  },
  {
    name: "Privacy Policy",
    url: "privacy-policy",
  },
];

interface FooterProps {
  newTab?: boolean;
  company?: any;
}

const Footer: FC<FooterProps> = (props): JSX.Element => {
  const [newTab, setNewTab] = React.useState(false);
  const [linkUrl, setLinkUrl] = React.useState("");

  React.useEffect(() => {
    if (props.newTab !== undefined) setNewTab(props.newTab);
    if (window.location.origin === "https://solusinegeri.com") {
      setLinkUrl("https://solusinegeri.com");
    } else {
      setLinkUrl("https://dev.solusinegeri.com");
    }
  }, []);

  return (
    <footer>
      <div
        style={{ backgroundImage: `url(${backgroundBlack})` }}
        className="flex flex-col bg-cover font-poppins mt-8"
      >
        <div className="flex lg:flex-row flex-col items-center justify-between gap-5 lg:p-[60px] p-8">
          <div className="lg:w-1/2 w-full">
            <div className="sm:flex md:flex lg:flex items-center">
              <div className="flex items-center">
                <img
                  src={logoSolusiNegeri2}
                  alt=""
                  className="lg:h-[70px] h-[60px]"
                />
                {props.company && (
                  <p className="text-white font-semibold text-2xl">x</p>
                )}
              </div>
              {props.company && (
                <img
                  src={props.company?.companyLogo}
                  alt=""
                  className="lg:h-[50px] md:h-[50px] my-2 sm:my-0 h-[40px] sm:ml-5 md:ml-5"
                />
              )}
            </div>
            <p className="font-normal text-white lg:text-[40px] text-[32px]">
              Membantu Anda menjadi lebih maju dengan teknologi.
            </p>
          </div>
          <div className="lg:w-auto sm:w-1/2 w-4/5">
            <button
              type="button"
              onClick={() => window.open(`https://wa.me/+6289526643223`)}
              className="w-full text-white text-base font-semibold py-4 bg-gradient-to-r to-[#5C80FF] from-[#1A52E4] rounded-[5px] lg:px-10 px-2"
            >
              Hubungi Kami
            </button>
          </div>
        </div>
        <hr className="opacity-10" />
        <div className="flex md:justify-end justify-center w-full lg:p-[60px] p-5">
          <div className="flex w-full md:w-1/2 justify-between items-start lg:gap-5 gap-3">
            {newTab ? (
              <div className="flex flex-col gap-3 items-start">
                {navbarLinks.map((item, index) => (
                  <RouterLink
                    to={linkUrl}
                    state={item.url}
                    key={index}
                    className="text-[#B1B1B1] lg:text-base text-sm font-normal no-underline cursor-pointer text-start"
                  >
                    {item.name}
                  </RouterLink>
                ))}
              </div>
            ) : (
              <div className="flex flex-col gap-3 items-start">
                {navbarLinks.map((item, index) => (
                  <Link
                    to={item.url}
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                    key={index}
                    className="text-[#B1B1B1] lg:text-base text-sm font-normal no-underline cursor-pointer text-start"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            )}
            <div className="flex flex-col gap-3 items-start">
              {footerLinks.map((item, index) =>
                item.name === "Contact" ? (
                  <button
                    key={index}
                    onClick={() => window.open(`https://wa.me/+6289526643223`)}
                    className="text-[#B1B1B1] lg:text-base text-sm font-normal no-underline text-start"
                  >
                    {item.name}
                  </button>
                ) : (
                  <button
                    key={index}
                    onClick={() =>
                      window.open(
                        props.company
                          ? `${linkUrl}/${props.company?.initial}/${item.url}`
                          : `${linkUrl}/${item.url}`
                      )
                    }
                    className="text-[#B1B1B1] lg:text-base text-sm font-normal no-underline text-start"
                  >
                    {item.name}
                  </button>
                  // <RouterLink
                  //   to={item.url}
                  //   target="_blank"
                  //   key={index}
                  //   className="text-[#B1B1B1] lg:text-base text-sm font-normal no-underline"
                  // >
                  //   {item.name}
                  // </RouterLink>
                )
              )}
            </div>
            <div>
              <a
                href="mailto:hello@solusinegeri.com"
                className="text-[#B1B1B1] lg:text-base text-sm font-normal no-underline"
              >
                hello@solusinegeri.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
