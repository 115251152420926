/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Header from "../components/Header";
import background from "../assets/images/bg-green.png";
import background2 from "../assets/images/bg-green2.png";
import backgroundPeta from "../assets/images/bg-peta.png";
import backgroundBlack from "../assets/images/bg-black2.png";
import videoIcon from "../assets/images/video.svg";
import aws from "../assets/images/aws.png";
import fastAPI from "../assets/images/fastAPI.png";
import googleCloud from "../assets/images/googleCloud.png";
import kubernetes from "../assets/images/kubernetes.png";
import mongoDB from "../assets/images/mongoDB.png";
import python from "../assets/images/python.png";
import redis from "../assets/images/redis.png";
import spring from "../assets/images/spring.png";
import logoSolusiNegeri from "../assets/images/logo_solusi_negeri.png";
import donasi from "../assets/images/features/donasi.png";
import marketplace from "../assets/images/features/marketplace.png";
import virtualCard from "../assets/images/features/virtual_card.png";
import ppob from "../assets/images/features/ppob.png";
import topup from "../assets/images/features/topup.png";
import mutasi from "../assets/images/features/mutasi.png";
import invoice from "../assets/images/features/invoice.png";
import transfer from "../assets/images/features/transfer.png";
import donasi_page from "../assets/images/features/donasi_page.png";
import marketplace_page from "../assets/images/features/marketplace_page.png";
import virtual_card_page from "../assets/images/features/virtual_card_page.png";
import ppob_page from "../assets/images/features/ppob_page.png";
import topup_page from "../assets/images/features/topup_page.png";
import mutasi_page from "../assets/images/features/mutasi_page.png";
import invoice_page from "../assets/images/features/invoice_page.png";
import transfer_page from "../assets/images/features/transfer_page.png";
import merchant_page from "../assets/images/features/merchant/merchant_page.png";
import mutasi_merchant from "../assets/images/features/merchant/mutasi.png";
import pos_merchant from "../assets/images/features/merchant/pos.png";
import card_merchant from "../assets/images/features/merchant/card.png";
import barcode_merchant from "../assets/images/features/merchant/barcode.png";
import invoice_merchant from "../assets/images/features/merchant/invoice.png";
import topup_merchant from "../assets/images/features/merchant/topup.png";
import withdraw_merchant from "../assets/images/features/merchant/withdraw.png";
import phone1 from "../assets/images/phone1.png";
import phone2 from "../assets/images/phone2.png";
import device from "../assets/images/device.png";
import checklist from "../assets/images/checklist.svg";
import bni from "../assets/images/banks/bni.png";
import bsi from "../assets/images/banks/bsi.png";
import bri from "../assets/images/banks/bri.png";
import xendit from "../assets/images/banks/xendit.png";
import jakone_pay from "../assets/images/banks/jakone_pay.png";
import bank_dki from "../assets/images/banks/bank_dki.png";
import danamon from "../assets/images/banks/danamon.png";
import fazz from "../assets/images/banks/fazz.png";
import twoPhones from "../assets/images/two_phones.png";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Footer from "../components/Footer";
import * as headerAnimation from "../assets/images/mockup/header.json";
import * as memberAnimation from "../assets/images/mockup/member.json";
import * as merchantAnimation from "../assets/images/mockup/merchant2.json";
import * as companyAnimation from "../assets/images/mockup/Company.json";
import * as communityAnimation from "../assets/images/mockup/Community.json";
import * as kampusAnimation from "../assets/images/mockup/Kampus.json";
import * as governmentAnimation from "../assets/images/mockup/Government.json";
import * as appStore from "../assets/images/mockup/App-Store.json";
import * as googlePlay from "../assets/images/mockup/Google-Play.json";
import Lottie from "react-lottie";
import { useLocation } from "react-router";
import YouTube from "react-youtube";
import { company, removeCompany, setCompany } from "../components/DataCompany";
import axios from "axios";

let tech2 = [
  {
    image: python,
  },
  {
    image: spring,
  },
  {
    image: fastAPI,
  },
  {
    image: googleCloud,
  },
  {
    image: mongoDB,
  },
  {
    image: aws,
  },
  {
    image: redis,
  },
  {
    image: kubernetes,
  },
];

let features = [
  {
    icon: donasi,
    image: donasi_page,
    name: "Donasi",
    desc: "Menu yang digunakan untuk menggalang dana atau donasi.",
    child: {
      icon: virtualCard,
      image: virtual_card_page,
      name: "Virtual Card",
      desc: "Menu yang digunakan untuk membuat kartu digital yag dapat dijadikan menjadi identitas dan kartu transaksi.",
    },
  },
  {
    icon: marketplace,
    image: marketplace_page,
    name: "Marketplace",
    desc: "Menu yang dapat digunakan untuk member berbelanja berbagai kebutuhan.",
    child: {
      icon: ppob,
      image: ppob_page,
      name: "PPOB",
      desc: "Menu yang dapat digunakan member untuk membeli pulsa, kuota. listrik dan kebutuhan lainnya.",
    },
  },
  {
    icon: topup,
    image: topup_page,
    name: "Top Up",
    desc: "Menu yang digunakan untuk menambah saldo utama.",
    child: {
      icon: invoice,
      image: invoice_page,
      name: "Invoice",
      desc: "Menu yang digunakan untuk melihat tagihan dan membayarkannya secara digital dan terdapat riwayat tagihan.",
    },
  },
  {
    icon: mutasi,
    image: mutasi_page,
    name: "Mutasi",
    desc: "Menu yang digunakan untuk melihat riwayat mutasi saldo.",
    child: {
      icon: transfer,
      image: transfer_page,
      name: "Transfer",
      desc: "Menu yang digunakan untuk transfer saldo antar member danantar bank.",
    },
  },
];

let merchants = [
  {
    icon: mutasi_merchant,
    image: merchant_page,
    name: "Mutasi",
    desc: "Fitur untuk melihat alur masuk dan keluarnya saldo merchant dari semua transaksi yang dilakukan.",
    child: {
      icon: marketplace,
      image: merchant_page,
      name: "Marketplace",
      desc: "Fitur untuk mengelola pesanan transaksi pembelian online dari customer.",
    },
  },
  {
    icon: pos_merchant,
    image: merchant_page,
    name: "POS",
    desc: "Fitur untuk mengelola produk dan transaksi pembelian manual, serta menerima pembayarannya dengan berbagi metode (tunai, kartu, barcode, dan link checkout).",
    child: {
      icon: card_merchant,
      image: merchant_page,
      name: "Transaksi Kartu & Cek Saldo Kartu",
      desc: "Fitur untuk menerima pembayaran dari customer menggunakan metode pembayaran kartu serta melayani pengecekan saldo pada kartu member.",
    },
  },
  {
    icon: barcode_merchant,
    image: merchant_page,
    name: "Pembayaran Via Barcode",
    desc: "Fitur merchant untuk menerima pembayaran dari customer menggunakan metode scan barcode dan buat barcode.",
    child: {
      icon: topup_merchant,
      image: merchant_page,
      name: "Topup Member",
      desc: "Fitur untuk melayani transaksi top up saldo member melalui app merchant.",
    },
  },
  {
    icon: invoice_merchant,
    image: merchant_page,
    name: "Invoice",
    desc: "Fitur untuk melihat dan membayar tagihan digital, serta membuat tagihan secara digital.",
    child: {
      icon: withdraw_merchant,
      image: merchant_page,
      name: "Pencairan Saldo Merchant",
      desc: "Fitur untuk menarik saldo hasil penjualan merchant ke rekening merchant dengan metode manual atau otomatis (sesuai kebijakan instansi).",
    },
  },
];

let banks = [
  {
    icon: bri,
  },
  {
    icon: bsi,
  },
  {
    icon: bni,
  },
  {
    icon: danamon,
  },
  {
    icon: bank_dki,
  },
  {
    icon: jakone_pay,
  },
  {
    icon: xendit,
  },
  {
    icon: fazz,
  },
];

const Home = () => {
  const [openTab, setOpenTab] = React.useState(1);
  const [showModal, setShowModal] = React.useState(false);
  const location: any = useLocation();
  const pathname = location?.pathname?.split("/");
  const initial = pathname[1].toUpperCase() || "";
  const [data, setData] = React.useState<any>(null);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: headerAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const memberOptions = {
    loop: true,
    autoplay: true,
    animationData: memberAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const merchantOptions = {
    loop: true,
    autoplay: true,
    animationData: merchantAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const companyOptions = {
    loop: true,
    autoplay: true,
    animationData: companyAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const campussOptions = {
    loop: true,
    autoplay: true,
    animationData: kampusAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const communityOptions = {
    loop: true,
    autoplay: true,
    animationData: communityAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const governmentOptions = {
    loop: true,
    autoplay: true,
    animationData: governmentAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const appStoreOptions = {
    loop: true,
    autoplay: true,
    animationData: appStore,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const googlePlayOptions = {
    loop: true,
    autoplay: true,
    animationData: googlePlay,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const getCompany = () => {
    if (initial !== "TERM-CONDITION")
      axios
        .get(`/user/info/company/get/${initial}`)
        .then((res) => {
          setCompany(res);
          setData(res.data.data);
        })
        .catch((err) => {
          setData(null);
          console.log(err);
        });
    else removeCompany();
  };

  React.useEffect(() => {
    if (initial) {
      getCompany();
    } else {
      removeCompany();
    }
    if (location.state !== null) {
      const element = document.getElementById(location.state);
      if (element) element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <section className="font-poppins">
      <Header company={data} />
      <div
        className={
          data
            ? "container mx-auto md:pt-[140px] pt-12"
            : "container mx-auto md:pt-[120px] pt-12"
        }
        id="about"
      >
        <div
          style={{ backgroundImage: `url(${background})` }}
          className="h-full lg:min-h-screen w-full bg-cover lg:rounded-[40px] rounded-none"
        >
          <div className="flex flex-col items-center pt-[77px]">
            <h1 className="text-white text-center font-bold lg:text-4xl text-[28px] lg:w-3/5 w-4/5">
              Kelola{" "}
              <span className="text-[#FA951E] italic">
                self-payment ecosystem
              </span>{" "}
              instansi Anda dengan satu aplikasi berbasis{" "}
              <span className="text-[#FA951E] italic">digital</span> yang
              terintegrasi
            </h1>
            <h5 className="text-white lg:text-xl text-base text-center lg:w-1/2 w-4/5 mt-[18px]">
              Co-Branding E-Money Closeloop Anda sesuai dengan kebutuhan &
              secara instan.
            </h5>
            <div className="flex items-center justify-center gap-3 mt-10 mb-7 w-full px-5">
              <button
                type="button"
                onClick={() => window.open(`https://wa.me/+6289526643223`)}
                className="bg-[#FA951E] text-white font-semibold lg:text-base text-sm py-3 lg:px-[52px] lg:w-auto w-1/2 rounded-[10px]"
              >
                Gabung Sekarang
              </button>
              <button
                type="button"
                onClick={() => {
                  setShowModal(true);
                  // setPlaying(true);
                }}
                className="bg-[#43A38D] text-white font-semibold lg:text-base text-sm py-3 lg:px-[52px] lg:w-auto w-1/2 justify-center rounded-[10px] flex lg:gap-2 gap-1 items-center"
              >
                <img src={videoIcon} alt="" />
                <span>Watch Video</span>
              </button>
            </div>
            <div>
              <Lottie options={defaultOptions} />
            </div>
          </div>
        </div>
        <div className="container mx-auto grid lg:grid-cols-5 sm:grid-cols-4 grid-cols-3 items-center justify-center lg:gap-5 gap-3 lg:mt-[100px] mt-[50px]">
          {tech2.map((el: any, index: number) => (
            <div
              key={index}
              className={`${
                index === 5 ? "lg:col-start-2 w-full mx-auto" : "w-full mx-auto"
              }`}
            >
              <img
                src={el.image}
                alt=""
                className="lg:max-h-[40px] max-h-[20px] lg:max-w-[160px] max-w-[75px] mx-auto"
              />
            </div>
          ))}
        </div>
        <div
          style={{ backgroundImage: `url(${backgroundPeta})` }}
          className="container mx-auto flex flex-col justify-center mt-24 bg-contain bg-no-repeat bg-center"
        >
          <div className="w-4/5 mx-auto">
            <h1 className="lg:text-5xl text-[32px] font-bold text-center">
              Siapa yang Menggunakan Solusinegeri?
            </h1>
            <p className="lg:text-2xl text-base text-secondary font-semibold text-center mt-8 mb-12">
              “<span className="text-[#058567]">Institusi</span>,{" "}
              <span className="text-[#058567]">komunitas</span>, atau{" "}
              <span className="text-[#058567]">pemerintah daerah</span> dengan
              jumlah pengguna yang banyak membutuhkan transaksi digital untuk
              mendukung ekosistem internalnya.”
            </p>
            <div className="grid lg:grid-cols-4 grid-cols-2 items-center justify-between gap-12 pt-6">
              <div className="text-center">
                <div className="relative">
                  <div className="absolute right-0 max-[639px]:w-1/3 mt-[-17%]">
                    <Lottie options={campussOptions} />
                  </div>
                  <h2 className="md:text-[40px] text-base font-medium lg:text-center text-start">
                    <span className="text-primary">+</span>
                    5000
                  </h2>
                </div>
                <p className="md:text-base text-xs font-medium text-[#737B8B] mt-3">
                  Smart Payment For Campuss
                </p>
              </div>
              <div className="text-center">
                <div className="relative">
                  <div className="absolute right-0 max-[639px]:w-1/3 mt-[-17%]">
                    <Lottie options={governmentOptions} />
                  </div>
                  <h2 className="md:text-[40px] text-base font-medium lg:text-center text-start">
                    <span className="text-primary">+</span>
                    514
                  </h2>
                </div>
                <p className="md:text-base text-xs font-medium text-[#737B8B] mt-3">
                  Smart Payment For Government
                </p>
              </div>
              <div className="text-center">
                <div className="relative">
                  <div className="absolute right-0 max-[639px]:w-1/3 mt-[-17%]">
                    <Lottie options={communityOptions} />
                  </div>
                  <h2 className="md:text-[40px] text-base font-medium lg:text-center text-start">
                    <span className="text-primary">+</span>
                    10000
                  </h2>
                </div>
                <p className="md:text-base text-xs font-medium text-[#737B8B] mt-3">
                  Smart Payment For Community
                </p>
              </div>
              <div className="text-center">
                <div className="relative">
                  <div className="absolute right-0 max-[639px]:w-1/3 mt-[-17%]">
                    <Lottie options={companyOptions} />
                  </div>
                  <h2 className="md:text-[40px] text-base font-medium lg:text-center text-start">
                    <span className="text-primary">+</span>
                    33000
                  </h2>
                </div>
                <p className="md:text-base text-xs font-medium text-[#737B8B] mt-3">
                  Smart Payment For Company
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="portofolio" className="container mx-auto lg:pt-24 pt-12">
        <div
          style={{ backgroundImage: `url(${backgroundBlack})` }}
          className="flex flex-col gap-6 justify-center bg-cover lg:rounded-[42px] rounded-none"
        >
          <div className="flex md:flex-row flex-col items-center justify-between gap-5 px-4">
            <div>
              <Lottie options={memberOptions} />
            </div>
            <div className="lg:pr-[120px] pr-[0px]">
              <img
                src={logoSolusiNegeri}
                alt=""
                className="lg:w-20 lg:h-12 w-[54px] h-[30px]"
              />
              <h1 className="lg:text-5xl text-3xl text-thirdy font-semibold mt-5 lg:mt-8">
                Solusi Negeri Member
              </h1>
              <p className="text-white lg:text-2xl text-base font-normal lg:mt-11 mt-1">
                Aplikasi yang digunakan member dengan dibekali berbagai fitur
                untuk menjalankan berbagai aktivitas.
              </p>
            </div>
          </div>
          <div className="flex md:flex-row flex-col-reverse items-center justify-between gap-5 px-4 pb-8 lg:pb-5">
            <div className="lg:pl-[120px] pl-[0px] w-full lg:w-1/2">
              <img
                src={logoSolusiNegeri}
                alt=""
                className="lg:w-20 lg:h-12 w-[54px] h-[30px]"
              />
              <h1 className="lg:text-5xl text-3xl text-thirdy font-semibold mt-5 lg:mt-8">
                Solusi Negeri Merchant
              </h1>
              <p className="text-white lg:text-2xl text-base font-normal lg:mt-11 mt-1">
                “Aplikasi yang digunakan oleh merchant dan memiliki fitur untuk
                memudahkan transaksi.”
              </p>
            </div>
            <div>
              <Lottie options={merchantOptions} />
            </div>
          </div>
        </div>
      </div>
      <div
        id="features"
        className="container mx-auto text-center lg:pt-24 pt-12"
      >
        <h1 className="lg:text-6xl text-3xl font-semibold mb-4">
          Fitur yang ada di Solusi Negeri
        </h1>
        <p className="lg:text-xl text-sm font-normal text-[#B1B1B1] lg:w-1/3 w-1/2 mx-auto">
          Fitur yang disesuaikan dengan kebutuhanmu ada di dalam Solusiegeri.
        </p>
        <div className="flex items-center justify-center gap-5 lg:my-9 my-6">
          <button
            className={`${
              openTab === 1 ? "text-primary green-border" : "text-[#D9D9D9]"
            } font-semibold lg:text-2xl text-base hover:green-border hover:text-primary`}
            onClick={() => setOpenTab(1)}
          >
            Member
          </button>
          <button
            className={`${
              openTab === 2 ? "text-primary green-border" : "text-[#D9D9D9]"
            } font-semibold lg:text-2xl text-base hover:green-border hover:text-primary`}
            onClick={() => setOpenTab(2)}
          >
            Merchant
          </button>
        </div>
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 50,
            },
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {openTab === 1
            ? features.map((item, index) => (
                <SwiperSlide
                  key={index}
                  className="flex flex-col justify-between"
                  style={{ background: "transparent" }}
                >
                  <div className="bg-[#FAFBFD] md:w-full mx-3 h-[240px] lg:h-[358px] mb-6 rounded-[20px] flex justify-between gap-0 lg:gap-3">
                    <div className="lg:pt-9 pt-[22px] lg:pl-9 pl-[22px] lg:pr-4 pr-1 lg:pb-4 pb-[22px] text-start w-1/2">
                      <div className="bg-white lg:w-[64px] w-10 rounded-[10px] shadow-3xl lg:mb-5 mb-3 p-2">
                        <img src={item.icon} alt="" />
                      </div>
                      <h3 className="lg:text-2xl text-xl text-[#13171C] font-bold">
                        {item.name}
                      </h3>
                      <p className="lg:text-base sm:text-sm text-xs text-[#434950] font-medium font-montserrat lg:mt-4 mt-1">
                        "{item.desc}"
                      </p>
                    </div>
                    <div className="h-full md:w-1/2 relative">
                      <img
                        src={item.image}
                        alt=""
                        className="md:absolute mx-auto max-[400px]:w-full max-md:w-2/3 bottom-0"
                      />
                    </div>
                  </div>
                  <div className="bg-[#FAFBFD] md:w-full mx-3 h-[240px] lg:h-[358px] rounded-[20px] flex justify-between gap-0 lg:gap-3">
                    <div className="lg:pt-9 pt-[22px] lg:pl-9 pl-[22px] lg:pr-4 pr-1 lg:pb-4 pb-[22px] text-start w-1/2">
                      <div className="bg-white lg:w-[64px] w-10 rounded-[10px] shadow-3xl lg:mb-5 mb-3 p-2">
                        <img src={item.child.icon} alt="" />
                      </div>
                      <h3 className="lg:text-2xl text-xl text-[#13171C] font-bold">
                        {item.child.name}
                      </h3>
                      <p className="lg:text-base sm:text-sm text-xs text-[#434950] font-medium font-montserrat lg:mt-4 mt-1">
                        "{item.child.desc}"
                      </p>
                    </div>
                    <div className="h-full md:w-1/2 relative">
                      <img
                        src={item.child.image}
                        alt=""
                        className="md:absolute mx-auto max-[400px]:w-full max-md:w-2/3 bottom-0"
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))
            : merchants.map((item, index) => (
                <SwiperSlide
                  key={index}
                  className="flex flex-col justify-between"
                  style={{ background: "transparent" }}
                >
                  <div className="bg-[#FAFBFD] md:w-full mx-3 h-[240px] lg:h-[358px] mb-6 rounded-[20px] flex justify-between gap-0 lg:gap-3">
                    <div className="lg:pt-9 pt-[22px] lg:pl-9 pl-[22px] lg:pr-4 pr-1 lg:pb-4 pb-[22px] text-start w-1/2">
                      <div className="bg-white lg:w-[64px] w-10 rounded-[10px] shadow-3xl lg:mb-5 mb-3 p-2">
                        <img src={item.icon} alt="" />
                      </div>
                      <h3 className="lg:text-2xl text-xl text-[#13171C] font-bold">
                        {item.name}
                      </h3>
                      <p className="lg:text-base sm:text-sm text-xs text-[#434950] font-medium font-montserrat lg:mt-4 mt-1">
                        "{item.desc}"
                      </p>
                    </div>
                    <div className="h-full w-1/2 relative">
                      <img
                        src={item.image}
                        alt=""
                        className="md:absolute mx-auto max-[400px]:w-full max-md:w-2/3 bottom-0"
                      />
                    </div>
                  </div>
                  <div className="bg-[#FAFBFD] md:w-full mx-3 h-[240px] lg:h-[358px] rounded-[20px] flex justify-between gap-0 lg:gap-3">
                    <div className="lg:pt-9 pt-[22px] lg:pl-9 pl-[22px] lg:pr-4 pr-1 lg:pb-4 pb-[22px] text-start w-1/2">
                      <div className="bg-white lg:w-[64px] w-10 rounded-[10px] shadow-3xl lg:mb-5 mb-3 p-2">
                        <img src={item.child.icon} alt="" />
                      </div>
                      <h3 className="lg:text-2xl text-xl text-[#13171C] font-bold">
                        {item.child.name}
                      </h3>
                      <p className="lg:text-base sm:text-sm text-xs text-[#434950] font-medium font-montserrat lg:mt-4 mt-1">
                        "{item.child.desc}"
                      </p>
                    </div>
                    <div className="h-full w-1/2 relative">
                      <img
                        src={item.child.image}
                        alt=""
                        className="md:absolute mx-auto max-[400px]:w-full max-md:w-2/3 bottom-0"
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
        </Swiper>
      </div>
      <div id="benefit" className="lg:pt-24 pt-12">
        <div
          style={{ backgroundImage: `url(${background2})` }}
          className="container mx-auto lg:mb-24 mb-12 flex flex-col justify-center bg-cover lg:rounded-[42px] rounded-none"
        >
          <div className="flex lg:flex-row flex-col-reverse items-end gap-5">
            <div className="relative h-full w-full">
              <img src={device} alt="" className="relative lg:w-auto w-52" />
              <div className="absolute flex bottom-0 lg:right-0 max-lg:left-[20%] lg:h-auto h-[78%]">
                <img src={phone2} alt="" className="relative left-[12%]" />
                <img src={phone1} alt="" className="relative" />
              </div>
            </div>
            <div className="w-full py-5 lg:pl-[100px] lg:pr-5 pl-4 pr-4">
              <h1 className="lg:text-4xl text-2xl text-white font-medium mt-8 mb-4 lg:text-start text-center">
                Keuntungan menggunakan
                <span className="text-[#FA951E] italic"> Solusi Negeri</span>
              </h1>
              <div className="flex items-center gap-4 lg:mb-6 mb-4">
                <img src={checklist} alt="" className="lg:w-8 w-5" />
                <p className="lg:text-2xl text-base text-white font-normal">
                  Laporan keuangan menjadi{" "}
                  <span className="font-semibold italic">digital</span>
                </p>
              </div>
              <div className="flex items-center gap-4 lg:mb-6 mb-4">
                <img src={checklist} alt="" className="lg:w-8 w-5" />
                <p className="lg:text-2xl text-base text-white font-normal">
                  Memudahkan{" "}
                  <span className="font-semibold italic">
                    monitoring aktivitas
                  </span>{" "}
                  instansi
                </p>
              </div>
              <div className="flex items-center gap-4 lg:mb-6 mb-4">
                <img src={checklist} alt="" className="lg:w-8 w-5" />
                <p className="lg:text-2xl text-base text-white font-normal">
                  Pembayaran tagihan rutin secara{" "}
                  <span className="font-semibold italic">digital</span>
                </p>
              </div>
              <div className="flex items-center gap-4 lg:mb-6 mb-4">
                <img src={checklist} alt="" className="lg:w-8 w-5" />
                <p className="lg:text-2xl text-base text-white font-normal">
                  Transaksi{" "}
                  <span className="font-semibold italic">real-time</span>{" "}
                  dilengkapi dengan{" "}
                  <span className="font-semibold italic">riwayat</span>
                </p>
              </div>
              <div className="flex items-center gap-4 lg:mb-6 mb-4">
                <img src={checklist} alt="" className="lg:w-8 w-5" />
                <p className="lg:text-2xl text-base text-white font-normal">
                  Integrasi{" "}
                  <span className="font-semibold italic">open API</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-auto text-center mb-28">
          <h1 className="lg:text-6xl text-[32px] font-semibold text-black mb-4">
            Bank Partner
          </h1>
          <p className="lg:text-xl text-sm font-normal text-[#B1B1B1]">
            Solusi Negeri sudah bekerja sama dengan beberapa bank yang ada di
            Indonesia.
          </p>
          <Swiper
            // slidesPerView={2}
            spaceBetween={50}
            breakpoints={{
              100: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 50,
              },
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper custom-swiper lg:mt-32 mt-28"
          >
            {banks.map((item, index) => (
              <SwiperSlide key={index} style={{ background: "transparent" }}>
                <div className="lg:p-[61px] p-9 bg-white rounded-full shadow-4xl">
                  <img src={item.icon} alt="" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="container mx-auto bg-white">
          <div
            style={{ backgroundImage: `url(${background2})` }}
            className="flex lg:flex-row flex-col justify-center bg-cover lg:rounded-[42px] rounded-none lg:mt-48 mt-28 lg:mb-28 mb-10"
          >
            <div className="lg:w-1/2 w-full lg:pl-20 pl-8 lg:pr-0 pr-8 lg:pt-28 pt-11 lg:pb-0 pb-8">
              <h1 className="lg:text-5xl text-[32px] text-white font-bold mb-11">
                Nikmati Kemudahan Bertransaksi Dengan Solusinegeri
              </h1>
              <button
                type="button"
                onClick={() => window.open("https://wa.me/+6289526643223")}
                className="bg-[#FA951E] rounded-xl px-10 py-4 text-white text-base font-semibold"
              >
                Hubungi Kami
              </button>
            </div>
            <div>
              <img src={twoPhones} alt="" />
            </div>
          </div>
        </div>
        <div className="container mx-auto">
          <h1 className="md:text-5xl text-[28px] font-bold text-primary text-center">
            Mitra yang sudah bergabung dapat menggunakan aplikasi kami secara{" "}
            <span className="text-[#FA951E] italic">Instan</span>
          </h1>
          <div className="flex justify-center items-center mx-auto w-full sm:w-2/3 md:w-1/2 mt-12 mb-16">
            <Lottie options={appStoreOptions} />
            <Lottie options={googlePlayOptions} />
          </div>
        </div>
        <Footer company={data} />
      </div>

      {/* MODAL */}
      <div
        className={`${showModal ? "block" : "hidden"} relative z-[70]`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="flex flex-col justify-center items-center relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all min-[600px]:w-[90%] md:w-[85%] lg:w-[70%] w-full sm:my-8 p-4">
              <div className="container">
                <YouTube videoId="H-asb0GceTc" iframeClassName="w-full" />
              </div>
              <div className="flex justify-end w-full mt-4">
                <button
                  onClick={() => {
                    setShowModal(false);
                    window.location.reload();
                  }}
                  className="bg-white text-red-600 font-medium border-red-600 border-[1px] md:w-1/5 w-1/3 py-2 rounded-lg"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
